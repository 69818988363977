/* src/components/Alerts.css */
.alerts-container {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    margin: 20px auto;
    width: 50%;
    max-width: 500px;
    box-sizing: border-box;
  }
  
  .alerts-container h3 {
    margin-bottom: 20px;
    font-size: 1.5em;
    color: #333;
  }
  
  .alerts-container form {
    display: flex;
    flex-direction: column;
  }
  
  .alerts-container form div {
    margin-bottom: 15px;
  }
  
  .alerts-container label {
    margin-bottom: 5px;
    font-weight: bold;
    display: block;
  }
  
  .alerts-container input,
  .alerts-container select {
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .alerts-container button {
    padding: 10px 20px;
    background-color: #282c34;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .alerts-container button:hover {
    background-color: #555;
  }
  
  .alerts-container p {
    margin-top: 15px;
    color: green;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .alerts-container {
      width: 75%;
    }
  }
  
  @media (max-width: 480px) {
    .alerts-container {
      width: 90%;
    }
  }
  