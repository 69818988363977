/* src/App.css */
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.charts-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px;
}

@media (max-width: 768px) {
  .charts-container {
    grid-template-columns: 1fr;
  }
}

form {
  margin: 20px;
}

form div {
  margin-bottom: 10px;
}

button {
  padding: 10px 20px;
  background-color: #282c34;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #555;
}
